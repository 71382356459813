import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm;
  remember = false;
  wrongCredential = false;
  error = { login: null, password: null };
  errorMessage = null;
  showPassword = false;
  loading = false;

  constructor(private formBuilder: FormBuilder, private loginService: LoginService) {
    this.loginForm = this.formBuilder.group({
      login: ['', [Validators.required, Validators.minLength(1)]],
      password: ['', [Validators.required, Validators.minLength(4)]],
    });
  }
  ngOnInit() {}

  ngOnDestroy() {
    this.loading = false;
  }

  onSumbit(loggedData) {
    loggedData.sesame_token = null;
    if (loggedData.login === '' && loggedData.password) {
      console.log(loggedData);
      this.error.login = loggedData.login === '' ? 'Login must be filled' : null;
      this.error.password = loggedData.password === '' ? 'Password must be filled' : null;
      return this.error;
    }
    this.loading = true;
    this.loginService.login(loggedData, this.remember).subscribe(
      (res) => {
        if (res.status === 500) {
          this.error.login = 'Invalid username';
          this.error.password = 'Invalid password';
        }
      },
      (err) => {
        this.loading = false;
        this.error.login = 'Invalid username';
        this.error.password = 'Invalid password';
      }
    );
  }
  changeChecked() {
    this.remember = !this.remember;
  }
  showHidePassword() {
    this.showPassword = !this.showPassword;
  }
  resetError() {
    if (this.error) {
      this.error.login = null;
      this.error.password = null;
      // this.loginForm.reset();
    }
  }
}
